export default {
    navbarList:['Home','Products','Application','Service','About'],
    search:'search',
    questionTitle:'FAQ',
    contactUs:'Contact',
    indexBannerName:'Understand',
    indexHotName:'Hot Product',
    indexIndustryName:'Products',
    hotProducts:'Hot Products',
    productClass:'Product Class',
    companyNews:'Company News',
    indexServeTitle:`For Customized use science 
                     And technology to serve the public`,
    indexIndustryList:[
        {
            chinese:'Scientific Research',
            english:'Scientific Research',
        },
        {
            chinese:'Microscope Application',
            english:'Microscope Application',
        },
        {
            chinese:'Dustrial Application',
            english:'Dustrial Application',
        },
        {
            chinese:'Teaching Researc',
            english:'Teaching Research',
        },
    ],
    footerNav:[
        {
          cn:'About',
          child:[
            {
              text:'0755-23200330-8008',type:2,path:'',
            },
            {
              text:`Room 1609, Building 4, Tian'an Yungu Phase II, 2018 Xuegang Road, Bantian Street, Longgang District, Shenzhen`,type:3,path:'',
            },
          ],
        },
        // {
        //   cn:'Products',
        //   child:[
        //     {
        //       text:'后台系统管理',type:1,path:'',
        //     },
        //     {
        //       text:'客户端系统',type:1,path:'',
        //     },
        //   ],
        // },
        {
          cn:'Service',
          child:[
            // {
            //   text:'Watch',type:1,path:'',
            // },
            {
              text:'Android Apps',type:1,path:'/serve/resourceDownload',
            },
          ],
        },
        {
          cn:'About',
          child:[
            {
              text:'Introduce',type:1,path:'/aboutUs/index',
            },
            {
              text:'Qualifications',type:3,path:'',
            },
          ],
        },
    ],
    aboutNav:['Profile','Culture','Technology','Vision'],
    serveNav:['FAQ','Software Download'],
    more:'More',
    detail:'Detail',
    parameterNav:['Characteristic','Technical Features'],
    technicalFeatures:'Features',
    resourceDownLoad:"Download",
    downloadVersion:'Version',
    downloadSize:'Size',
    downloadUpdat:'Update',
    download:'Down',
    downloadBannerTitle:'Service support',
    downloadBannerText:'We provide you with efficient service guarantee and diversified service support plans to make customers more convenient and at ease',
    questionList:[
        {
            question:'Camera not responding or abnormal when triggered',
            answer:'A: Check if the software has selected the correct triggering mode; Check if the wire connections are correct; Check if the trigger signal level and polarity are correct.',
        },
        {
            question:'When starting the camera, there is a color deviation in the image',
            answer:'A: Please click on white balance or regional white balance once, or manually adjust the RGB gain; Please check if you have selected the appropriate color matrix mode.',
        },
        {
            question:'The camera image is not realistic',
            answer:'A: Please check if the adjustment of parameters such as white balance, gamma, contrast, and black balance is appropriate.',
        },
        {
            question:'There may be flickering or water ripples in the image screen',
            answer:'A: Please confirm if the light source is stable. If the light source is stable, enable the flicker suppression function.'
        },
    ],
    industrysList:[
        {
            module:'Industrial Application',
            brief:'Because of the performance of industrial camera is stable and reliable and easy installation, compact camera setting is not easy to damage, long continuous working time, can be used in a poor condition, the shutter time is very short, can capture fast moving objects, make up the human impact by environmental factors. Widely used in such as the development of metal materials and resin materials, materials used to observe the impact of internal cracks in the direction of state, etc., can be used to analyze the material destruction of structure material, and quality inspection, workpiece measurement etc..',
            effect:'It is widely used in industry',
            effectDetail:'Machine vision   industrial surveillance and inspection   material inspection PCB and SMT detect printed and packed fabric inspection Four wheel positioning  solar panel inspection  robot automation Dynamic motion monitoring',
            achievement:[],
        },
        {
            module:'Microscope Application',
            brief:`Biomedical microscopy. Our machine vision camera provides color images for medical and biological sciences. With high precision and color reduction characteristics of a high degree of assurance, microscopy performance, excellent color fidelity, detection and storage reliability. If you're still looking for powerful, simple, effective, inexpensive cameras, looking at technology will be a good choice.`,
            effect:'Microimaging  Life sciences',
            effectDetail:'',
            achievement:['Medical care','Chemistry Biotechnology','Fluorescence','Metallography'],
        },
        {
            module:'Scientific Research',
            brief:'The industrial camera is applied to micro image acquisition, and the object size can be measured, and the pictures are photographed and stored. In addition, there are skin inspection, hair root inspection, textile inspection, biological observation, gemstone identification, visual aid for people with visual impairment, printing, inspection, industrial inspection, precision machinery and many other applications.',
            effect:'Life sciences',
            effectDetail:'',
            achievement:['Life sciences','Medical care','Technology'],
        },
        {
            module:'Teaching Research',
            brief:'Teaching research. The characteristics of shoot, simple operation, high resolution, multi function, to reproduce the vivid experiment scene, the experimental process complete reduction, microscopic appearance and visual feedback, collecting information and statistics, to meet the requirements of electronic information courses experimental teaching, can realize image capture, display and image processing.',
            effect:'Other application',
            effectDetail:'',
            achievement:['Machine learning','Pattern recognition','Three-dimensional measurement','Image segmentation','Artificial intelligence'],
        },
    ],
    AboutUs:'About Us',
    aboutUsCopy:'ShenZhen YANGWANG Technology Co.',
    aboutUsEstablished:'Established in',
    year:'Year',
    aboutUsInfo:'Our self-owned office building is based in Shenzhen. We have rich experience in the field of intelligent device, multimedia education etc., and we are specializing in researching, developing, manufacturing and sales. Our R&D team with experienced engineers spare no efforts to develop and innovate. Meanwhile, we also keep pace with international advance technology, and use it on our products. Our market covers more than 30 countries and regions all over the world, and we have received lots of positive feedbacks in domestic and oversea market.',
    aboutUsRange:'30 countries and regions',
    aboutUsBand:'Our main products include industrial camera, microscope, all in one ultra thin computer, intelligent multimedia blackboard etc. We have our own brand-Yangwangzhe, and we also provide OEM service. Based on the tenet of ‘Innovation, Reputation, profitability’, we are aiming at meeting our customers’ requirements. Reputation is the basis for our company to exist, and we provide professional training for our employees, which assure our products high quality.',
    aboutUsSlogan:`We keep cultivating our company's culture and team spirit. For each one of us, we will try our best to focus on work and take responsibility. And for co-workers, we will respect and help each other. For customers, we offer our best service and hope to build a win-win relationship. We seize the opportunity and face the challenge, and try to make core value for our customers by providing advance scientific instruments and inspected devices. High quality product and service is our key to develop. Let’s work hard together and create a bright future.`,
    cultureTitle:"culture",
    cultureBrief:`The company adheres to the core values of "setting an example for oneself, giving full effort; taking initiative in work, efficient execution; respecting and accepting colleagues, grateful and dedicated; sincere service to customers, and win-win cooperation", focusing on customer needs and challenges, providing advanced scientific instruments and testing equipment, and creating value for customers with dedication.`,
    cultureList:[
      {name:'Business Philosophy',label:'Integrity and pragmatism',eng:'Integrity and pragmatism'},
      {name:'Product Strategy',label:'Industrial Standards Professional Quality Humanized Design',eng:'Industrial Standards Professional Quality Humanized Design'},
      {name:'Spirit of Enterprise',label:'Tailored to customers,using technology to serve the public',eng:'Tailored to customers,using technology to serve the public'},
      {name:'Service Tenet',label:'Better update More timely',eng:'Better update More timely'},
    ],
    technologyName:'Core technology ',
    technologyBrief:'The Yangwang Technology Product Center provides customers with comprehensive and professional pre-sales technical solutions and product consultations, as well as fast after-sales technical support services. Yangwang Technology industrial cameras have been widely used in fields such as microscopes, portraits, solar energy, industrial testing, biomedical science, teaching, iris recognition, machine vision, and scientific research.',
    technologyItem1Name:'Wangzhe Series · Industrial Camera',
    technologyItem1Brief:'Adopting dual ARM architecture and single chip design',
    technologyItem1Desc:'The performance is reliable and stable, with simpler and more powerful features compared to other FPGA designs. The image algorithm has been deeply cultivated by professional engineers in the industry for more than 10 years, and has reached a leading level in the industry. The sensors are all made of Panasonic products from Japan. Yangwang Technology is committed to becoming the first brand of industrial cameras in China.',
    technologyItem2Name:'From USB 2.0 cameras to VGA cameras',
    technologyItem2Brief:'从From Gigabit Ethernet cameras to USB 3.0 cameras',
    technologyItem2Desc:'From CMOS to CCD, from 300000 pixels to 18 million pixels, from economy cameras to professional cameras, there is a wide variety and complete range. Excellent quality is the most reliable guarantee of our products. We are committed to providing customers with cost-effective products, comprehensive industry solutions, and first-class after-sales service in the long term.',
    technologyMore:'Learn more about products',
}